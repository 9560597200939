import React from 'react';
import GlobalStyle from './styles/global';
import Home from './pages/Home';


const  App = () => {
  return (
    <>
      <Home/>
      <GlobalStyle />
    </>
  );
}

export default App;
